import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["searchField", "customMonthTab"]

  static values = {
    url: String,
    selectedMonth: String
  }

  search(){
    const jqSearchField = $(this.searchFieldTarget)
    if (jqSearchField.inputmask('isComplete') && this.selectedMonthValue !== jqSearchField.val()) {
      this.customMonthTabTarget.innerHTML = '<i class="fa fa-spin fa-spinner custom-month"></i>'
      $.rails.ajax({
        type: 'get',
        url: this.urlValue,
        data: `month=${jqSearchField.val()}`,
        success: () => {
          this.selectedMonthValue = jqSearchField.val();
        }
      })
    }
  }
}
