$(document).on('turbo:load', function() {
    if($('.card.side-modal').length) {
        $('main > .container-fluid').append("<div class='modal-overflow'></div>");
    }

    $('.side-modal-icon').on('click', function() {
        $(this).parent().toggleClass('open');
        if($('.side-modal.open').length) {
            $('.modal-overflow').css('z-index', '1300').css('opacity', '1');
        } else {
            $('.modal-overflow').css('z-index', '-1').css('opacity', '0');
        }
    });

    $('.modal-overflow').on('click', function() {
        $(this).css('z-index', '-1').css('opacity', '0');
        $('.side-modal.open').each( function() {
            $(this).removeClass('open');
        });
    });
});
