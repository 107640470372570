import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
    change(event) {
        let country = event.target.value;
        let url = new URL(window.location.href);
        url.searchParams.set("country", country);
        window.location.href = url.href;
    }
}
