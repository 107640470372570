import { Controller } from "stimulus";
import { patch } from "@rails/request.js";
import Sortable from "sortablejs";

export default class extends Controller {
    connect() {
        this.sortable = Sortable.create(this.element, {
            onEnd: this.itemMoveEnd.bind(this)
        });
    }

    async itemMoveEnd(event) {
        let id = event.item.dataset.id;
        let url = this.data.get("update-url").replace(":id", id);
        let data = new FormData();
        data.append("new_position", event.newIndex);
        data.append("sort_order", this.sortable.toArray().join(","));
        data.append("instance_id", this.data.get("instance_id"))

        await patch(url, { body: data, responseKind: "turbo-stream" })
    }
}
