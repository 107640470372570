import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["timePeriod", "propagateCheckbox"]

  update(e) {
    if (e.currentTarget.dataset.period === "next") {
      this.propagateCheckboxTarget.disabled = true;
    } else {
      this.timePeriodTarget.value = e.currentTarget.dataset.period;
      this.propagateCheckboxTarget.disabled = false;
    }
  }
}
