import { Controller } from "stimulus";

export default class extends Controller {
    onClick(event) {
        // Ignore the href value.
        event.preventDefault();

        // Grey-out the toggle button.
        const target = event.target;
        target.classList.toggle("grey-filter");

        // Toggle snow visibility.
        $("#snowflakes").toggle();

        // Toggle cookie value to store user preference.
        const cookie_key = "christmas";
        const current_mode = Cookies.get(cookie_key);
        const new_mode = current_mode === "grinch" ? "christmas" : "grinch";
        Cookies.set(cookie_key, new_mode, { expires: 365 /* days */ });
    }
}
