$(document).on('turbo:load', function() {
    $('#sort-col input').each(function() {
        var colData;
        colData = $(this).attr('id');
        if ($(this).is(':checked') || localStorage.getItem("order_col"+colData)==='true') {
            $(this).addClass('show');
            if (!$(this).is(':checked')) {
                $(this).prop('checked', true);
            }
        } else {
            $('[data-col=' + colData + ']').each(function() {
                $(this).css('display', 'none');
            });
        }
    });


    $('#sort-col input[type=checkbox]').on('change', function() {
        var colData, show;
        colData = $(this).attr('id');
        if ($(this).hasClass('show')) {
            $('[data-col=' + colData + ']').each(function() {
                $(this).css('display', 'none');
            });
            show = 'false'
        } else {
            $('[data-col=' + colData + ']').each(function() {
                $(this).removeAttr('style');
            });
            show = 'true'
        }

        localStorage.setItem("order_col"+colData, show);
        $(this).toggleClass('show');
    });
});
