$(document).on('turbo:load', function() {
    if ($('#mail_template_mailer').toArray()[0]) {
        loadMailerData($('#mail_template_mailer')[0])
    }
});

function loadMailerData(el) {
    $.ajax( "/admin/mail_templates/assigns?mailer="+encodeURIComponent(el.value))
        .done((json) => {
            $('#assigns')[0].innerHTML = '<pre>' + JSON.stringify(json, null, 4) + '</pre>'
        });
}

function makeCkEditor(elementId, options) {
    const defaultOptions = {
        language: 'cs',
        height: 800,
        readlOnly: false,
        allowedContent: true,
        fullPage: true,
        fillEmptyBlocks: false,
        forcePasteAsPlainText: true,
        entities: false
    }
    CKEDITOR.replace( elementId, {
        ...defaultOptions, // this needs to be above options
        ...options, // so that the options property "override" the default one
    })
}

window.loadMailerData = loadMailerData
window.makeCkEditor = makeCkEditor
