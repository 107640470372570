import { Controller } from "stimulus";

export default class extends Controller {
    static targets = ["colorPickerElement", "colorFieldElement"];

    connect() {
        this.updatePicker();
    }

    updatePicker() {
        const picker = this.colorPickerElementTarget;
        const field = this.colorFieldElementTarget;

        picker.value = field.value;
    }

    updateField() {
        const picker = this.colorPickerElementTarget;
        const field = this.colorFieldElementTarget;

        field.value = picker.value;
    }
}