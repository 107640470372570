$(document).on('turbo:load', function() {
    function findDropdown(el) {
        attr = el.attr('id');
        dropdown = $('[data-id=' + attr + ']');
        height = dropdown.height() / 3;
    }

    var dropdownLi = $("[data-dropdown='true']");
    dropdownLi.hover(function (e) {
        findDropdown($(this));
        fromTop = e.target.getBoundingClientRect().top - height;
        dropdown.css('top', fromTop + 'px').fadeIn(100);
    }, function () {

    });
    dropdownLi.mouseleave(function () {
        if (dropdown.is(":hover")) {
            dropdown.hover(function () {
            }, function () {
                dropdown.fadeOut(100);
            });
        } else {
            dropdown.fadeOut(100);
        }
    });
});
