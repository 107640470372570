// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// force webpacker to include required files - used in loading images
const cache = {};
function importAll(r) {
    r.keys().forEach((key) => (cache[key] = r(key)));
}

import {HeaderObserver} from "../src/javascripts/wizard/components/HeaderObserver";

importAll(require.context('../images', true));
require("@rails/ujs").start();
require("@rails/activestorage").start();
require("channels");

import "@hotwired/turbo-rails"
import './stylesheets.scss'
import 'cocoon-js'
import 'bootstrap'
import 'select2'
import 'inputmask/dist/jquery.inputmask'
import "controllers"
import "chartkick/chart.js"

import '../src/javascripts/bootstrap_select/bootstrap-select'
import '../src/javascripts/jquery.autocomplete'

import '../src/javascripts/tools/autocomplete'
import '../src/javascripts/tools/detail_box'
import '../src/javascripts/tools/menu'
import '../src/javascripts/tools/side_modal'
import '../src/javascripts/tools/sort_col'
import '../src/javascripts/tools/mail_template_buttons'
import '../src/javascripts/tools/mail_templates'
import '../src/javascripts/tools/print_templates'
import '../src/javascripts/wizard/brand/default_language_selector'
import '../src/javascripts/wizard/brand/color_fields_linker'
import '../src/javascripts/wizard/brand/checkbox_toggler'
import '../src/javascripts/wizard/brand/checkbox_switcher'
import '../src/javascripts/wizard/brand/liquid_input_filler'
import '../src/javascripts/wizard/brand/tabber'
import '../src/javascripts/wizard/branch/fees_month_switcher'
import '../src/javascripts/wizard/branch/fees_mass_updater'
import '../src/javascripts/qr'

import 'ace-builds/src-noconflict/ace';
import "ace-builds/webpack-resolver"
window.Cookies = require('js-cookie')

$(document).on('turbo:load', function () {
    $(window).scroll(function () {
        $('footer').css({
            'left': $(this).scrollLeft()
        });
    });
    $(document).on('change', ".account-number-check-box", function () {
        $(".account-number-check-box").prop('checked', false);
        $(this).prop('checked', true);
    });
    $(document).on('change', ".eet-certificate-check-box", function () {
        $(".eet-certificate-check-box").prop('checked', false);
        $(this).prop('checked', true);
    });
    $('.selectpicker').selectpicker();
    $('[data-toggle="popover"]').popover();
    $('.select2').select2({theme: 'bootstrap4', width: '100%', closeOnSelect: false});
    $(document).on('click', '[data-set-payment-method-enabled]', function(e) {
        e.preventDefault();
        $(e.currentTarget).closest('form').find('input[type="checkbox"]').prop('checked', $(this).data('set-payment-method-enabled'));
    });
    $("input[data-inputmask]").inputmask();
});

window.jQuery = $;
window.$ = $;
