$(document).on('click', '#brand-basic-information a[data-language]', function (e) {
  e.preventDefault();
  var $target = $(e.target);
  var $currentDefault = $('.default-language');
  setDefaultLanguageTo($(this).data('language'));
  $currentDefault.siblings('a').show();
  $currentDefault.removeClass('default-language').hide();
  $target.siblings('span').addClass('default-language').show();
  $target.hide();
})

$(document).on('change', 'input[name="basic_information[languages][]"]', function (e) {
  var $this = $(this);
  if ($this.prop('checked')) {
    if ($('.default-language').length) {
      $this.siblings('a').show();
    } else {
      setDefaultLanguageTo($this.siblings('a').data('language'));
      $this.siblings('span').addClass('default-language').show();
    }
  } else {
    var wasDefault = $this.siblings('span').hasClass('default-language')
    $this.siblings('span').removeClass('default-language').hide();
    $this.siblings('a').hide();
    if (wasDefault) {
      var firstChecked = $('input[name="basic_information[languages][]"]:checked').first();
      if (firstChecked.length) {
        setDefaultLanguageTo(firstChecked.siblings('a').data('language'));
        firstChecked.siblings('span').addClass('default-language').show();
        firstChecked.siblings('a').hide();
      } else {
        setDefaultLanguageTo('');
      }
    }
  }
})

function setDefaultLanguageTo(languageId) {
  $('#basic_information_default_language').val(languageId);
}
