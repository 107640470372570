// Based off the module imports described in the ZXing README
import {BrowserMultiFormatReader, NotFoundException} from '@zxing/library/esm';


window.addEventListener('load', function () {

    $('#camera-modal').on('shown.bs.modal', function () {
        openQrModalProcedure();
    })

    $('#camera-modal').on('hide.bs.modal', function () {
        closeQrModalProcedure();
    })
    window.codeReader = new BrowserMultiFormatReader() // note the updated module reference
    console.log(codeReader);

})

window.addEventListener('turbo:load', function () {

    $('#camera-modal').on('shown.bs.modal', function () {
        openQrModalProcedure();
    })

    $('#camera-modal').on('hide.bs.modal', function () {
        closeQrModalProcedure();
    })
    window.codeReader = new BrowserMultiFormatReader() // note the updated module reference
    console.log(codeReader);

})


function openQrModalProcedure() {
    codeReader
        .decodeOnceFromVideoDevice(undefined, 'video')
        .then((result) => {
            $('#short_url_link_code').val(new URL(result.text).pathname.slice(1))
            $('#camera-modal').modal('hide');
        })
        .catch((err) => {
            if (err.message !== 'Video stream has ended before any code could be detected.') {
                alert(err);
            }
            $('#camera-modal').modal('hide');
        });
}

function openQrModal() {
    $('#camera-modal').modal('show');
}

function closeQrModalProcedure() {
    codeReader.stopStreams();
    codeReader.reset();
}

function modalChanged(event) {
    console.log(event);
}

window.modalChanged = modalChanged;
window.openQrModal = openQrModal;
window.openQrModalProcedure = openQrModalProcedure;
window.closeQrModalProcedure = closeQrModalProcedure;


