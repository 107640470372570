import { Controller } from "stimulus";

export default class extends Controller {
    static targets = ["input", "element"];

    connect() {
        this.toggle();
    }

    toggle() {
        this.elementTarget.style.display = this.inputTarget.checked ? "block" : "none";
    }
}
