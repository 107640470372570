$(document).on('turbo:load', function() {
    $('.detail-box [data-toggle="collapse"]')
        .on('click', function() {
            var modelName = $(this).parent('.detail-box').attr('data-model');
            var collapse = $(this).attr('href');
            if ($(this).hasClass('collapsed')) {
                show = 'true'
            } else {
                show = 'false'
            }
            localStorage.setItem(modelName+collapse, show);
        })
        .each( function() {
            var modelName = $(this).parent('.detail-box').attr('data-model');
            var collapse = $(this).attr('href');
            if (localStorage.getItem(modelName+collapse)==='true') {
                $(collapse).collapse('show')
            }
        });
});
