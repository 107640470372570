$(document).on('turbo:load', function() {
    if ($('#print_template_triggerable_type_id').toArray()[0]) {
        loadPrintTemplateData($('#print_template_triggerable_type_id')[0])
    }
});

function loadPrintTemplateData(el) {
    $.ajax( "/admin/print_templates/assigns?triggerable_type_id="+encodeURIComponent(el.value))
        .done((json) => {
            $('#assigns')[0].innerHTML = '<pre>' + JSON.stringify(json, null, 4) + '</pre>'
        });
}

window.loadPrintTemplateData = loadPrintTemplateData

