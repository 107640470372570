/*
  USAGE:
    1. get the input object (example bellow named `exampleInput`)
    2. prepare render data by calling
      `const transformedExampleKeys = transformKeyObjectForRender(exampleInput)`
    3. create onClick callback
      - can use prepared `insertTranslationOnCursor` or `insertKeyOnCursor`
    4. create root section:
      ```
        const rootSection = createRootSection({
          rootLabel: 'Translations',
          renderValues: transformedExampleKeys,
          buttonAction: insertTranslationOnCursor,
        })
      ```
    5. add some HTML element (div) into the HTML template where you want the section to be rendered
      - eg. `<div id="templateRoot"></div>
      - use different id for each section!
    6. mount the rootSection into said element:
      `renderToContainer({ containerId: 'templateRoot', renderElement: rootSection })`
    7. ???
    8. PROFIT
*/
const BRANCH_TYPE = 'branch'
const LEAF_TYPE = 'leaf'
// TRANSFORMATION FUNCTION
/*
  INPUT:
    - js object where values are of a type object or TInputLeaf
      ```
        type TInputLeaf = string | number | {
          type: "leaf"
          title: string | number
          label: string | number
        }
        const exampleInput = {
          custom_mail_templates: {
            label: "All templates", // !!! "label" is reserved, as well as "type" and "title" – do not use them as loco keys
            someKey: 'some value',
            common: {
              contact: 'Please contact us', // string type
              address: {        // advanced type (with custom label) -> add `type: 'leaf'`, use previous value as `title` and custom label as `label`
                type: 'leaf',
                title: 'Some address',
                label: 'Customer address',
              },
            }
          }
        }
      ```
  OUTPUT:
    - the input with values mapped into array
    - values in these arrays will be either
        ```
          type TLeafNode = {
            key: string
            type: "leaf"
            label: string
            title: string
          }
        ```
      for a leaf or
        ```
          type TBranchNode = {
            key: string
            label: string
            type: "branch"
            children: (TBranchNode | TLeafNode)[]
          }
        ```
      for a branch
*/
export function transformKeyObjectForRender(object) {
    const entries = Object.entries(object)
    const getEntryLabel = ([key, value]) => {
        if (typeof value !== 'object') {
            return key
        }
        return value.label ?? key
    }
    const getEntryTitle = ([key, value]) => {
        if (typeof value !== 'object') {
            return value
        }
        return value.title ?? key
    }
    return entries.map(entry => {
        const [key, value] = entry
        const label = getEntryLabel(entry)
        const commonProps = { key, label }
        if (typeof value !== 'object' || value?.type === 'leaf') {
            return {
                ...commonProps,
                type: LEAF_TYPE,
                title: getEntryTitle(entry),
            }
        }
        return {
            ...commonProps,
            type: BRANCH_TYPE,
            children: transformKeyObjectForRender(value),
        }
    })
}
// STYLES
/*
  - scoped to the content inside #containerId element
*/
const templatePadding = '0.5rem'
function getCommonStyles(containerId) {
    return `
    #${containerId} *:focus,
    #${containerId} *:active {
      outline: none;
    }
    #${containerId} summary {
      font-size: 1.0rem;
      padding: 0.25rem 0px;
      cursor: pointer;
    }
    #${containerId} .section-content {
      padding-left: calc(2 * ${templatePadding});
    }
    #${containerId} .section-content .section-content {
      padding-left: calc(3 * ${templatePadding});
    }
    #${containerId} .section-content .section-content .section-content {
      padding-left: calc(4 * ${templatePadding});
    }
    #${containerId} .section-content .section-content .section-content .section-content {
      padding-left: calc(5 * ${templatePadding});
    }
    #${containerId} .section-content .section-content .section-content .section-content .section-content {
      padding-left: calc(6 * ${templatePadding});
    }
    #${containerId} .section-content .section-content .section-content .section-content .section-content .section-content {
      padding-left: calc(7 * ${templatePadding});
    }
    #${containerId} .insert-btn {
      margin: 0.25rem;
    }
    #${containerId} .insert-btn:hover {
      border-color: #dc3545;
      background-color: #dc3545;
    }
  `
}
// ELEMENT CREATION FUNCTIONS
/*
  Basically just use the `createRootSection` (which is `createSection` with few param renames to be more clear).
  - renders section with `rootLabel` (as a label) and `renderValues (inside)
  - for non-leaf nodes, renders nested section, for leaf nodes renders button elements
  - when a (leaf) button is click, the `buttonAction` callback is called (receiving `buttonNode: TLeafNode` as an argument)
*/
export function createRootSection({ rootLabel, renderValues, buttonAction, availableTranslations }) {
    return createSection(
        { label: rootLabel, children: renderValues },
        { buttonAction },
        availableTranslations
    )
}
// create HTML details-summary section
function createSection(
    { key: parentKey = null, label, children },
    buttonProps,
    availableTranslations
) {
    const summary = document.createElement('summary')
    summary.innerText = label
    const content = document.createElement('div')
    content.classList.add('section-content')
    // children ar of a type (TLeafNode | TBranchNode)[]
    children.forEach(({ type, key: childKey, ...restOfChild }) => {
        // combine key from this child with its parent key (the section)
        // -> leaf keys will be dot separated route through the input object
        const key = parentKey ? `${parentKey}.${childKey}` : childKey
        const child = type === LEAF_TYPE
            ? createInsertButton({ ...restOfChild, key }, buttonProps, availableTranslations)
            : createSection({ ...restOfChild, key }, buttonProps, availableTranslations)
        content.appendChild(child)
    })
    const section = document.createElement('details')
    section.appendChild(summary)
    section.appendChild(content)
    return section
}
// create a button with onClick action
function createInsertButton(buttonNode, { buttonAction }, availableTranslations) {
    const { label, title } = buttonNode //: TLeafNode
    const button = document.createElement('a')
    button.innerText = label
    button.setAttribute('title', title)
    // base btn classes used in config already
    button.classList.add('btn')
    button.classList.add('btn-primary')
    // specific class used in the styles above
    button.classList.add('insert-btn')
    // Remove locale from the key if the locale is the first element
    buttonNode["key"] = keyWithoutLocale(buttonNode["key"], availableTranslations)
    button.addEventListener('click', () => buttonAction(buttonNode))
    button.addEventListener('mousedown', event => event.preventDefault())
    return button
}

export function availableTranslationsContent() {
    return JSON.parse(document.getElementById("query_form_select_ops").value);
}

function keyWithoutLocale(key, availableTranslations) {
    const splitKey = key.split(".");

    if (availableTranslations.hasOwnProperty(splitKey[0])) {
        return splitKey.slice(1).join(".");
    }

    return key;
}

// RENDER FUNCTION
/*
  ReactDom-like function mounting `renderElement` into an element with `containerId`
*/
export function renderToContainer({ containerId, renderElement }) {
    const container = document.querySelector(`#${containerId}`)
    if (!container) {
        console.error(`Missing <div id="${containerId}"></div> in the DOM!`)
        return
    }
    const styles = document.createElement('style')
    styles.innerText = getCommonStyles(containerId)
    container.appendChild(styles)
    container.appendChild(renderElement)
}
// INSERTION FUNCTIONS
/*
  Use these two exported functions as `buttonAction` callbacks
    or feel free to write your own using the helper functions bellow.
  The insertion function used as `buttonAction` can expect one param with properties
    "key", "title" and "label" (basically `buttonNode` in `createInsertButton`, so TLeafNode).
*/
export function insertTranslationOnCursor({ key }) {
    insertStringOnCursor(`{{ translate ${key} }}`)
}
export function insertKeyOnCursor({ key }) {
    insertStringOnCursor(`{{ ${key} }}`)
}
function insertStringOnCursor(insertedString) {
    // get focused element
    const focusedElement = document.activeElement
    if (!focusedElement) {
        return
    }

    aceEditor.getSession().insert(aceEditor.getCursorPosition(), insertedString);

    if (focusedElement.value === undefined || focusedElement.selectionStart === undefined) {
        // seems like the focused element is not na input?
        return
    }
    const input = focusedElement
    const cursorPosition = input.selectionStart
    const { updatedText, trimmedSpacesBefore } = insertStringIntoText({
        insertedString,
        originalText: input.value,
        insertionIndex: cursorPosition,
    })
    const spaceBeforeAndAfter = 2
    const newCursorPosition = cursorPosition - trimmedSpacesBefore + (insertedString.length + spaceBeforeAndAfter)
    // update input value and set cursor just after the inserted part
    input.value = updatedText
    if (input.setSelectionRange) {
        try {
            input.setSelectionRange(newCursorPosition, newCursorPosition)
        } catch (err) {
            // setSelectionRange does not apply on focused input (eg checkbox)
        }
    }
}
function insertStringIntoText({ originalText, insertedString, insertionIndex }) {
    // split string where new value should be inserted
    const partBeforeInsertion = originalText.slice(0, insertionIndex)
    const partAfterInsertion = originalText.slice(insertionIndex)
    // trim both parts
    const trimmedPartBeforeInsertion = partBeforeInsertion.trimEnd()
    const trimmedPartAfterInsertion = partAfterInsertion.trimStart()
    // get trimmed space counts
    const trimmedSpacesBefore = partBeforeInsertion.length - trimmedPartBeforeInsertion.length
    const trimmedSpacesAfter = partAfterInsertion.length - trimmedPartAfterInsertion.length
    // get updated string by inserting new value
    const updatedText = `${trimmedPartBeforeInsertion} ${insertedString} ${trimmedPartAfterInsertion}`
    return { updatedText, trimmedSpacesBefore, trimmedSpacesAfter }
}
window.transformKeyObjectForRender = transformKeyObjectForRender
window.createRootSection = createRootSection
window.renderToContainer = renderToContainer
window.insertTranslationOnCursor = insertTranslationOnCursor
window.insertKeyOnCursor = insertKeyOnCursor
window.availableTranslationsContent = availableTranslationsContent
