import { Controller } from "stimulus";

// Prevent chrome from autofill fields (eg username). We set field as readonly if it hasnt focus, so chrome ignore it.
export default class extends Controller {
  connect() {
    this.element.setAttribute('readonly', 'readonly');
    this.element.classList.add('ignore-readonly-style');
    this.element.addEventListener('focus', () => {
      this.element.removeAttribute('readonly');
    });
    this.element.addEventListener('blur', () => {
      this.element.setAttribute('readonly', 'readonly');
    });
  }
}
