$(document).on('input', '.fees-updatable-main-values', function (e) {
  var checkbox = $(e.currentTarget);
  var massAbsoluteValue = checkbox.closest('tbody').find('.fees-updatable-absolute-value-all');
  var massPercentageValue = checkbox.closest('tbody').find('.fees-updatable-percentage-value-all');
  var massCancelable = checkbox.closest('tbody').find('.fees-updatable-cancelable-all');

  if (massAbsoluteValue.length) {
    var currentAbsoluteValue = checkbox.closest('tr').find('.fees-updatable-absolute-value');
    if (checkbox.prop('checked')) {
      currentAbsoluteValue.val(massAbsoluteValue.val());
    } else {
      currentAbsoluteValue.val(currentAbsoluteValue.attr('data-original-value'));
    }
  }

  if (massPercentageValue.length) {
    var currentPercentageValue = checkbox.closest('tr').find('.fees-updatable-percentage-value');
    if (checkbox.prop('checked')) {
      currentPercentageValue.val(massPercentageValue.val());
    } else {
      currentPercentageValue.val(currentPercentageValue.attr('data-original-value'));
    }
  }

  checkbox.closest('tr').find('.fees-updatable-cancelable').prop('checked', massCancelable.prop('checked'))
})

// Save previous input value
$(document).on('input', '.fees-updatable-absolute-value, .fees-updatable-percentage-value', function (e) {
  var current = $(e.currentTarget);
  if (!current.closest('tr').find('.fees-updatable-main-values').prop('checked')){
    current.attr('data-original-value', current.val());
  }
});

// Dont allow user to edit field if he check Use main values. We cant set readonly for element, because it is not trigger validation if value is missing
$(document).on('keydown paste focus mousedown', '.fees-updatable-absolute-value, .fees-updatable-percentage-value', function (e) {
  if ($(e.currentTarget).closest('tr').find('.fees-updatable-main-values').prop('checked')){
    e.preventDefault();
  }
});

// Bulk updates
$(document).on('input', '.fees-updatable-main-values-all', function (e) {
  $(e.currentTarget).closest('tbody').find('.fees-updatable-main-values:not(:disabled)').prop('checked', e.currentTarget.checked);
  $(e.currentTarget).closest('tbody').find('.fees-updatable-main-values:not(:disabled)').trigger('input');
})

$(document).on('input', '.fees-updatable-absolute-value-all', function (e) {
  $(e.currentTarget).closest('tbody').find('.fees-updatable-main-values:checked').each(function(index){
    $(this).closest('tr').find('.fees-updatable-absolute-value').val(e.currentTarget.value);
  });
})

$(document).on('input', '.fees-updatable-percentage-value-all', function (e) {
  $(e.currentTarget).closest('tbody').find('.fees-updatable-main-values:checked').each(function(index){
    $(this).closest('tr').find('.fees-updatable-percentage-value').val(e.currentTarget.value);
  });
})

$(document).on('input', '.fees-updatable-cancelable-all', function (e) {
  $(e.currentTarget).closest('tbody').find('.fees-updatable-main-values:checked').each(function(index){
    $(this).closest('tr').find('.fees-updatable-cancelable').prop('checked', e.currentTarget.checked);
  });
})

// Highlighting of tab with invalid input
$(document).on('turbo:load refresh-fees-validation', function () {
  document.querySelectorAll('#fees-expenses-tab-content input[required=required]').forEach(function(el){
    $(el).on('invalid', function(e){
      const tab_id = $(this).closest('.custom-tab-pane').attr('id');
      $('a[href="#' + tab_id + '"]').addClass('invalid-input');
    })
  })
});
