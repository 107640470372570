$(document).on('click', '*[data-fees-period]', function (e) {
  e.preventDefault();

  // Show selected fee tab, hide others
  const expenseTab = $('#fees-expenses-tab-content');
  expenseTab.removeClass('custom_month current next').addClass(e.currentTarget.dataset.feesPeriod);
  expenseTab.find('.monthly-fee, .no-fees').hide();
  expenseTab.find('.' + e.currentTarget.dataset.feesPeriod).show();

  // Show selected service fee tab, hide others
  $('.service_fee').hide();
  $('.service_fee.' + e.currentTarget.dataset.feesPeriod).show();

  // Show selected commission fee tab, hide others
  $('.commission_fee').hide();
  $('.commission_fee.' + e.currentTarget.dataset.feesPeriod).show();

  // Highlight selected button and remove highlighting from others
  $(e.currentTarget).siblings('button').removeClass('selected');
  $(e.currentTarget).addClass('selected');

  $('#wizard_company_branches_commission_form_time_period').val(e.currentTarget.dataset.feesPeriod);
  const propagateCheckbox = $('#wizard_company_branches_commission_form_propagate');
  propagateCheckbox.prop("disabled", e.currentTarget.dataset.feesPeriod === "next")
})

$(document).on('click', '.fee-custom-month-button', function (e) {
  var selectedMonth = $('#custom_month');
  var selectedMonthButton = $('#commission-form .fee-custom-month-button');

  if (selectedMonth.inputmask('isComplete') && selectedMonthButton.attr('data-current-month') !== selectedMonth.val()) {
    $('table.custom_month').html('<i class="fa fa-spin fa-spinner custom-month"></i>')
    $.rails.ajax({
      type: 'get',
      url: selectedMonthButton.data('url'),
      data: `month=${selectedMonth.val()}`
    })
  }
});
