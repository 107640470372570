var ready;

ready = function() {
  return $('.autocomplete').autocomplete({
    serviceUrl: function() {
      return $(this).data('url');
    },
    autoSelectFirst: true,
    onSearchComplete: function(suggestion) {
      var $this;
      $this = $(this);
      console.log('onSearchComplete: ' + $this.val());
      if ($this.attr('id').slice(-6) !== "filter") {
        $('#' + $this.attr('id').replace("filter_query", "filter_value")).val('');
      }
      $this.parent().find('i.fa-check-circle').removeClass('fa-check-circle').removeClass('text-success').addClass('fa-circle text-danger');
      return $this.parent().find('span.id').html('# ');
    },
    onSelect: function(suggestion) {
      var $this;
      $this = $(this);
      console.log('You selected: ' + suggestion.value + ', ' + suggestion.data);
      if ($this.attr('id').slice(-6) !== "filter") {
        $('#' + $this.attr('id').replace("filter_query", "filter_value")).val(suggestion.data);
      }
      else if ( this.dataset['polymorphic'] == 'true' ) {
        // clean all autocomplete inputs
        $('.autocomplete').each(function() {
          $( this ).val('');
        });
        // fill in hidden autocomplete fields
        $('#' + $this.data('target-field-id') + '_id').val(suggestion.data);
        $('#' + $this.data('target-field-id') + '_type').val(suggestion.klass);

        // fill in used autocomplete field
        $this.val(suggestion.value);
      } else {
        $('#' + $this.data('target-field-id')).val(suggestion.data);
      }
      $this.parent().find('i.fa-circle').removeClass('fa-circle text-danger').addClass('fa-check-circle text-success');
      return $this.parent().find('span.id').html('#' + suggestion.data);
    }
  });
};

$(document).on('turbo:load', ready);
