$(document).on('click', '*[data-as-tab]', function (e) {
  e.preventDefault();
  var $this = $(this);
  $this.siblings('.selected').removeClass('selected');
  $this.addClass('selected');
  var tabContent = $($this.data('asTab'));
  tabContent.children().each(function(){
    if ($this.attr('href') == "#" + $(this).attr('id')) {
      $(this).show();
    } else {
      $(this).hide();
    }
  });
});
