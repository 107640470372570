$(document).on('input', '*[data-show-on-click]', function (e) {
  var $this = $(this);
  $this.data('showOnClick').split(' ').forEach(function (value) {
    if ($this.prop("checked")) {
      $(value).show();
    } else {
      $(value).hide();
    }
  });
})
