import { Controller } from "stimulus";


// Switching between tabs of external delivery fee form
export default class extends Controller {
  static targets = ["tabButton", "tabContent"]

  // Remove "selected" class from all buttons except one which was clicked
  // and show tab content with same css class as is on clicked button in data-period attribute
  switchTab(e) {
    e.preventDefault();
    this.tabButtonTargets.forEach((tabButton) => {
      if (tabButton === e.currentTarget) {
        tabButton.classList.add("selected")
        this._showTab(tabButton.dataset.period)
      } else {
        tabButton.classList.remove("selected")
      }
    })
  }

  _showTab(period) {
    this.tabContentTargets.forEach((tabContent) => {
      tabContent.style = tabContent.classList.contains(period) ? '' : 'display: none'
    })
  }
}
